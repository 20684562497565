export * from "./DataSetDetailPage";

export const DATA_SET_DETAIL_TITLE = "Data Set Submission";

export const linkToDataSetDetailPage = (transactionId = ":transactionId") =>
  `/app/data-tools/submissions/datasets/${transactionId}/detail`;

export type DataSetDetailPageParamType = {
  transactionId: string;
};

export const DATA_SET_DETAIL_BREADCRUMB = {
  title: DATA_SET_DETAIL_TITLE,
  path: linkToDataSetDetailPage(),
};
