import { DataTablePanel, StorageKeys } from "@validereinc/common-components";
import React from "react";

export const ConfigurationsTablePanel = ({
  filterConfigStorageKey,
  tableConfigStorageKey,
}: StorageKeys) => {
  return (
    <DataTablePanel
      storageKey={filterConfigStorageKey + tableConfigStorageKey}
    />
  );
};
