import { FilterPanel, StorageKeys } from "@validereinc/common-components";
import React from "react";

export const ConfigurationsFilterPanel = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  return (
    <FilterPanel
      storageKey={filterConfigStorageKey}
      defaultActiveKeys={["Filters"]}
      filters={[]}
    />
  );
};
