import {
  useGetOneTransactionDetail,
  useOriginalSubmissionFileDownload,
} from "#hooks/adapters/useTransactions";
import { useBreadcrumbs } from "#routers/breadcrumbsHelper";
import { DataToolsRoutePath } from "#src/routes/data-tools";
import { DataSubmissionsRoutePath } from "#src/routes/data-tools/submissions";
import {
  DATA_SET_DETAIL_BREADCRUMB,
  DATA_SET_DETAIL_TITLE,
} from "#routes/data-tools/submissions/datasets/[transactionId]/detail";
import {
  extractItemsFromTransactionDetails,
  getTransactionTitle,
  showErrorDetails,
  useDataSetDetail,
} from "#routes/data-tools/submissions/datasets/[transactionId]/detail/DataSetDetail.helpers";
import { DataSetDetailsPanel } from "#routes/data-tools/submissions/datasets/[transactionId]/detail/DataSetDetailsPanel";
import { DataSetErrorPanel } from "#routes/data-tools/submissions/datasets/[transactionId]/detail/DataSetErrorPanel";
import { DataSetRowTable } from "#routes/data-tools/submissions/datasets/[transactionId]/detail/DataSetRowTable";
import { DataSetStatusPanel } from "#routes/data-tools/submissions/datasets/[transactionId]/detail/DataSetStatusPanel";
import { Button, Column, Page, Row } from "@validereinc/common-components";
import React from "react";
import { LegacyBreadcrumbType } from "#src/Routers/breadcrumbsHelper";

export const DataSetDetailPage = () => {
  const {
    firstTransactionState: transaction,
    dataset,
    isLoading,
  } = useDataSetDetail();

  const { data: transactionDetailData } = useGetOneTransactionDetail();
  const showErrorInstances = showErrorDetails(
    extractItemsFromTransactionDetails(transactionDetailData)
  );

  const title = getTransactionTitle(transaction, dataset);

  const downloadOriginalSubmission = useOriginalSubmissionFileDownload();

  const actionRow = [
    <Button
      key="download-original-submission"
      variant="outline"
      onClick={() =>
        downloadOriginalSubmission.mutate({
          transactionId: transaction?.transaction_id ?? "",
          fileName: transaction?.original_file_name ?? "",
        })
      }
      isLoading={downloadOriginalSubmission.isLoading}
    >
      Download Original Submission
    </Button>,
  ];

  return (
    <Page
      title={title}
      category={DATA_SET_DETAIL_TITLE}
      isLoading={isLoading}
      actionRow={actionRow}
      breadcrumbs={useBreadcrumbs(
        [
          { title: DataToolsRoutePath.title },
          {
            title: DataSubmissionsRoutePath.title,
            path: () => DataSubmissionsRoutePath.path,
          } as LegacyBreadcrumbType,
          DATA_SET_DETAIL_BREADCRUMB,
        ],
        { 2: title }
      )}
    >
      <Row>
        <Column variant={6}>
          <DataSetStatusPanel />
        </Column>
        <Column variant={18}>
          <DataSetDetailsPanel />
        </Column>
      </Row>
      <Row>
        <Column variant={showErrorInstances ? 18 : 24}>
          <DataSetRowTable />
        </Column>
        {showErrorInstances ? (
          <Column variant={6}>
            <DataSetErrorPanel />
          </Column>
        ) : null}
      </Row>
    </Page>
  );
};
