import { useDataSetDetail } from "#routes/data-tools/submissions/datasets/[transactionId]/detail/DataSetDetail.helpers";
import { KeyValuePanel } from "@validereinc/common-components";
import { toStartCaseString } from "@validereinc/utilities";
import React from "react";

export const DataSetDetailsPanel = () => {
  const { finalTransactionState, dataset } = useDataSetDetail();

  return (
    <KeyValuePanel
      panelMaxColumnCount={2}
      panelKeyValueListProps={{ variant: "shaded" }}
      panelProps={{ title: "Submission Details" }}
      data={[
        {
          title: "Dataset Name",
          value: dataset?.name ? toStartCaseString(dataset.name) : "-",
        },
        {
          title: "File Name",
          value: finalTransactionState?.original_file_name ?? "-",
        },
        {
          title: "Import Type",
          value: dataset?.type ? toStartCaseString(dataset.type) : "-",
        },
        {
          title: "Total Rows",
          value: finalTransactionState?.total_count ?? "-",
        },
      ]}
    />
  );
};
