import { SetCleanHistoryPath } from "#routers/historyHelper";
import { EVENTS_ALL_TAB } from "#routes/events/all-events";
import { EVENT_CATEGORIES_TAB } from "#routes/events/event-categories";
import { SCENARIO_MANAGEMENT_TAB } from "#routes/scenarios";
import PageConfigs from "#src/Routers/PageConfigs";
import {
  AccountNavBarSettings,
  LegacyAccountNavBarSettings,
} from "#src/batteries-included-components/Layouts/Authentication/AccountNavBar";
import useLocalization from "#src/hooks/useLocalization";
import { SidebarTabType } from "@validereinc/common-components";
import cloneDeep from "lodash/cloneDeep";
import { useMemo } from "react";
import { OpenNewLink } from "../LayoutHelper";

export const LegacyOpsHubFeatures = {
  DATA_MANAGEMENT: "data_management",
  WORKFLOWS: "workflows",
  WORKFLOWS_MANAGE: "workflows_manage",
  INSTRUMENTS: "instruments",
  CHAINS_OF_CUSTODY: "chains_of_custody",
  DATA_MAPPING: "data_mapping",
  IMPORTS: "third_party_import",
  MANUAL_ENTRY: "manual_entry",
  ANNOTATIONS: "annotations",
  MEASUREMENTS: "measurements",
  TESTS: "tests_manage",
  SAMPLES: "samples",
  LAB_RESULTS: "lab_results",
  INVENTORIES: "inventories",
  RECORDS: "records",
  RECORDS_OF_QUALITY: "record_of_quality",
  RECORDS_OF_VOLUME: "record_of_volume",
  BALANCES: "balances",
  PRODUCTION: "production",
  PRODUCTION_STREAMS: "production_streams",
  PRODUCTION_REPORTS: "production_reports",
  EMISSIONS: "emissions",
  EMISSIONS_RECORDS: "emission_records",
  EMISSIONS_REPORTS: "emission_reports",
  ANALYZE: "analyze",
} as const;

// IMPROVE: this file needs to be refactored/removed in the routing engine rework
export const SIDEBAR_TABS: SidebarTabType[] = [
  {
    id: "home",
    title: "Home",
    icon: "home",
  },
  {
    id: "dashboards",
    title: "Dashboards",
    icon: "chart-bar",
  },
  {
    id: "organization",
    title: "Organization",
    icon: "tree-structure",
    nested: [
      {
        id: "organization_facilities",
        title: "facility_plural",
      },
      {
        id: "organization_flows",
        title: "flow_plural",
      },
      {
        id: "organization_networks",
        title: "Networks",
      },
      {
        id: "organization_equipment",
        title: "equipment_plural",
      },
      {
        id: "organization_devices",
        title: "Devices",
      },
    ],
  },
  {
    id: "records_v2",
    title: "Records",
    icon: "clipboard-text",
    nested: [
      {
        id: "records_v2_list",
        title: "Records",
      },
      {
        id: "records_v2_automation",
        title: "Record Automation",
      },
    ],
  },
  {
    id: "calculations",
    title: "Calculations",
    icon: "calculator",
    nested: [
      {
        id: "calculations",
        title: "Calculators",
      },
      {
        id: "calculations_estimation_methods",
        title: "Estimation Methods",
      },
    ],
  },
  {
    id: "reports",
    title: "Reports",
    icon: "presentation-chart",
    nested: [
      {
        id: "custom_reports",
        title: "Custom Reports",
      },
      {
        id: "templated_reports",
        title: "Templated Reports",
      },
    ],
  },
  {
    id: "carbon_emissions",
    title: "Emissions",
    icon: "globe-hemisphere-west",
    nested: [
      {
        id: "carbon_emissions_overview",
        title: "Overview",
      },
      {
        id: "carbon_emissions_records",
        title: "Records",
      },
      {
        id: "carbon_emissions_reports",
        title: "Reports",
      },
      {
        id: "default_values",
        title: "Default Values",
      },
    ],
  },
  {
    id: "alert_management",
    title: "Alert Management",
    icon: "warning",
    nested: [
      {
        id: "alerts",
        title: "Alerts",
      },
      {
        id: "alert_configuration",
        title: "Alert Configuration",
      },
    ],
  },
  {
    id: "forms",
    title: "Forms",
    icon: "article",
    nested: [
      {
        id: "form_categories",
        title: "Categories",
      },
      {
        id: "form_configs",
        title: "Templates",
      },
      {
        id: "forms",
        title: "Submissions",
      },
    ],
  },
  {
    id: "events",
    title: "Events",
    icon: "clock-counter-clockwise",
    nested: [EVENT_CATEGORIES_TAB, EVENTS_ALL_TAB],
  },
  {
    id: "scenarios",
    title: "Scenarios",
    icon: "atom",
    nested: [SCENARIO_MANAGEMENT_TAB],
  },
  {
    id: LegacyOpsHubFeatures.DATA_MANAGEMENT,
    title: "Data Management",
    icon: "database",
    nested: [
      {
        id: LegacyOpsHubFeatures.WORKFLOWS,
        title: "Workflow Tasks",
      },
      {
        id: LegacyOpsHubFeatures.WORKFLOWS_MANAGE,
        title: "Manage Workflows",
      },
      {
        id: LegacyOpsHubFeatures.INSTRUMENTS,
        title: "Instruments",
      },
      {
        id: LegacyOpsHubFeatures.CHAINS_OF_CUSTODY,
        title: "Chain of Custody",
      },
      {
        id: LegacyOpsHubFeatures.DATA_MAPPING,
        title: "Data Mapping",
      },
      {
        id: LegacyOpsHubFeatures.IMPORTS,
        title: "Imports",
      },
      {
        id: LegacyOpsHubFeatures.MANUAL_ENTRY,
        title: "Manual Entry",
      },
      {
        id: LegacyOpsHubFeatures.ANNOTATIONS,
        title: "Annotations",
      },
    ],
  },
  {
    id: "data_tools",
    title: "Data Tools",
    icon: "wrench",
    nested: [
      {
        id: "data_tools_submissions",
        title: "Data Submissions",
      },
    ],
  },
  {
    id: LegacyOpsHubFeatures.MEASUREMENTS,
    title: "Measurements",
    icon: "stack",
    nested: [
      {
        id: LegacyOpsHubFeatures.TESTS,
        title: "Tests",
      },
      {
        id: LegacyOpsHubFeatures.SAMPLES,
        title: "Samples",
      },
      {
        id: LegacyOpsHubFeatures.LAB_RESULTS,
        title: "Lab Results",
      },
      {
        id: LegacyOpsHubFeatures.INVENTORIES,
        title: "Inventories",
      },
    ],
  },
  {
    id: LegacyOpsHubFeatures.RECORDS,
    title: "Records",
    icon: "clipboard-text",
    nested: [
      {
        id: LegacyOpsHubFeatures.RECORDS_OF_QUALITY,
        title: "Record Of Quality",
      },
      {
        id: LegacyOpsHubFeatures.RECORDS_OF_VOLUME,
        title: "Record Of Volume",
      },
      {
        id: LegacyOpsHubFeatures.BALANCES,
        title: "Balances",
      },
    ],
  },
  {
    id: "workflows-new",
    title: "Workflows",
    icon: "list-checks",
    nested: [
      {
        id: "workflows_categories",
        title: "Categories",
      },
      {
        id: "workflows_templates",
        title: "Templates",
      },
      {
        id: "workflows_all",
        title: "All Workflows",
      },
      {
        id: "workflows_my_tasks",
        title: "Tasks",
      },
    ],
  },
  {
    id: LegacyOpsHubFeatures.PRODUCTION,
    title: "Production",
    icon: "production",
    nested: [
      {
        id: LegacyOpsHubFeatures.PRODUCTION_STREAMS,
        title: "Streams",
      },
      {
        id: LegacyOpsHubFeatures.PRODUCTION_REPORTS,
        title: "Reports",
      },
    ],
  },
  {
    id: LegacyOpsHubFeatures.EMISSIONS,
    title: "Emissions",
    icon: "globe-hemisphere-west",
    nested: [
      {
        id: LegacyOpsHubFeatures.EMISSIONS_RECORDS,
        title: "Records",
      },
      {
        id: LegacyOpsHubFeatures.EMISSIONS_REPORTS,
        title: "Reports",
      },
    ],
  },
  {
    id: LegacyOpsHubFeatures.ANALYZE,
    title: "Analyze",
    icon: "analyze",
  },
  {
    id: LegacyAccountNavBarSettings.DEFAULT,
    title: "Settings",
    icon: "gear-six",
    nested: [
      {
        id: LegacyAccountNavBarSettings.ACCOUNT,
        title: "Account",
      },
      {
        id: LegacyAccountNavBarSettings.COMPANY,
        title: "Company",
      },
    ],
  },
  {
    id: LegacyAccountNavBarSettings.HELP,
    title: "Help",
    icon: "question",
    nested: [
      {
        id: AccountNavBarSettings.KNOWLEDGE_BASE,
        title: "Knowledge Base",
      },
      {
        id: AccountNavBarSettings.CONTACT_SUPPORT,
        title: "Contact Support",
      },
    ],
  },
] as const;

// TODO: note this was temporarily refactored from what existed earlier as this
// is eventually intended to be fully thrown away in the routing engine
// refactor.
export const getPageConfigsToSidebarTabsMap = (tabs: SidebarTabType[]) => {
  const flatSidebarTabs = tabs.flatMap((tab) => {
    if (tab.nested) {
      return [tab, ...tab.nested];
    }

    return tab;
  });

  // WIP: PageConfig typing
  const pageConfigsToSidebarTabs = Object.keys(PageConfigs).reduce<
    Record<string, any>
  >((map, configKey) => {
    const associatedSidebarTab = flatSidebarTabs.find(
      (tab) => tab.id === configKey
    );

    if (!associatedSidebarTab) {
      map[configKey] = null;
      return map;
    }

    if (map[configKey]) {
      console.warn(
        `Validere App Navigation: Duplicate page configuration with id ${configKey}`
      );
    }

    map[configKey] = associatedSidebarTab;
    return map;
  }, {});

  return pageConfigsToSidebarTabs;
};

// TODO: note this was temporarily refactored from what existed earlier as this
// is eventually intended to be fully thrown away in the routing engine
// refactor.
const getSidebarTabWithLink = (
  sidebarTab: SidebarTabType,
  {
    overrides = {},
  }: {
    /** override certain tab properties */
    overrides: Partial<SidebarTabType>;
  }
) => {
  const pageConfig = PageConfigs[sidebarTab.id];
  let link;

  if (pageConfig?.link) {
    link = () => SetCleanHistoryPath(pageConfig.link);
  } else if (pageConfig?.externalLink) {
    link = () => OpenNewLink(pageConfig.externalLink);
  } else if (pageConfig?.action && overrides.link) {
    link = overrides.link;
  }

  return {
    ...sidebarTab,
    title: overrides.title ?? sidebarTab.title,
    link,
  };
};

// TODO: note this was temporarily refactored as this is eventually intended to be fully thrown away in the routing engine refactor.
export const useSidebarTabsWithMapping = (
  onClickOverrides: Record<
    SidebarTabType["id"],
    Pick<SidebarTabType, "id" | "link">
  > = {}
): [SidebarTabType[], boolean] => {
  const { localize, isLoading: isMappingLoading } = useLocalization();

  const mappedSidebarTabs = useMemo<SidebarTabType[]>(
    () =>
      cloneDeep(SIDEBAR_TABS).map((tab) => {
        if (tab.nested) {
          tab.nested = tab.nested.map((nestedTab) => {
            const onClickOverrideForTab = onClickOverrides[nestedTab.id];

            return getSidebarTabWithLink(nestedTab, {
              overrides: {
                title: localize(nestedTab.title),
                link: onClickOverrideForTab?.link,
              },
            });
          });
        }

        const onClickOverrideForTab = onClickOverrides[tab.id];

        return getSidebarTabWithLink(tab, {
          overrides: {
            title: localize(tab.title),
            link: onClickOverrideForTab?.link,
          },
        });
      }),
    [localize]
  );

  return [mappedSidebarTabs, isMappingLoading];
};
