import { useGetOneUser } from "#hooks/adapters/useUsers";
import {
  getStatusDisplayLabel,
  getTransactionStatusVariant,
} from "#routes/data-tools/submissions/datasets/DataSetsList.helpers";
import { useDataSetDetail } from "#routes/data-tools/submissions/datasets/[transactionId]/detail/DataSetDetail.helpers";
import { StatusPanel } from "@validereinc/common-components";
import { TransactionStatus } from "@validereinc/domain";
import { datetimeFormatter } from "@validereinc/utilities";
import React from "react";

export const DataSetStatusPanel = () => {
  const { firstTransactionState, finalTransactionState } = useDataSetDetail();

  const { data: user } = useGetOneUser({
    id: finalTransactionState?.user_id,
  });

  const isCompleted =
    finalTransactionState?.status &&
    finalTransactionState.status !== TransactionStatus.PENDING;

  return (
    <StatusPanel
      data={[
        {
          label: "Started",
          value: firstTransactionState?.created_at
            ? datetimeFormatter(new Date(firstTransactionState?.created_at))
            : "-",
        },
        {
          label: "Completed",
          value:
            isCompleted && finalTransactionState?.created_at
              ? datetimeFormatter(new Date(finalTransactionState?.created_at))
              : "-",
        },
        {
          label: "Submitted By",
          value: user?.name ?? "-",
        },
      ]}
      statusText={getStatusDisplayLabel(finalTransactionState?.status)}
      statusVariant={getTransactionStatusVariant(finalTransactionState?.status)}
    />
  );
};
