import {
  TRANSACTION_SOURCE_OPTIONS,
  TRANSACTION_STATUS_OPTIONS,
} from "#constants";
import { useListDatasets } from "#hooks/adapters/useDatasets";
import { FiltersConfig } from "#routes/data-tools/submissions/datasets/DataSetsList.helpers";
import {
  DateSelectorInput,
  DropdownInput,
  FilterPanel,
  StorageKeys,
} from "@validereinc/common-components";
import { TransactionSources } from "@validereinc/domain";
import subDays from "date-fns/subDays";
import startCase from "lodash/startCase";
import React from "react";

const defaultRetentionWindowInDays = 7;
const defaultDateRange = {
  from: subDays(new Date(), defaultRetentionWindowInDays),
  to: new Date(),
};

export const DataSetsFilterPanel = ({
  filterConfigStorageKey,
}: Pick<StorageKeys, "filterConfigStorageKey">) => {
  const { data: allDatasets, isLoading: allDatasetsIsLoading } =
    useListDatasets();

  return (
    <FilterPanel
      storageKey={filterConfigStorageKey}
      defaultActiveKeys={["Filters"]}
      defaultValue={{
        [FiltersConfig.source.name]: TransactionSources.UPLOAD,
        [FiltersConfig.date.name]: defaultDateRange,
      }}
      filters={[
        {
          component: (
            <DropdownInput
              key="dataset-filter"
              {...FiltersConfig.dataset}
              options={
                allDatasets?.data?.map((set) => ({
                  name: startCase(set.name),
                  id: set.id,
                })) ?? []
              }
              isLoading={allDatasetsIsLoading}
              labelKey="name"
              valueKey="id"
              isLabelShown={false}
              isInline
            />
          ),
        },
        {
          component: (
            <DropdownInput
              {...FiltersConfig.source}
              key="source-type-filter"
              options={TRANSACTION_SOURCE_OPTIONS}
              labelKey="label"
              valueKey="value"
              isLabelShown={false}
              isInline
            />
          ),
        },
        {
          component: (
            <DateSelectorInput
              {...FiltersConfig.date}
              key="date-filter"
              variant="time"
              isRange
              isInline
              isLabelShown={false}
              max={defaultDateRange.to}
            />
          ),
        },
        {
          section: "Filters",
          component: (
            <DropdownInput
              {...FiltersConfig.status}
              key="status-filter"
              options={TRANSACTION_STATUS_OPTIONS}
              labelKey="label"
              valueKey="value"
              isFluid
            />
          ),
        },
      ]}
    />
  );
};
