import { useStorageKey } from "#src/hooks/useStorageKey";
import React from "react";
import { ConfigurationsFilterPanel } from "./ConfigurationsFilterPanel";
import { ConfigurationsTablePanel } from "./ConfigurationsTablePanel";

export const ConfigurationsListTab = () => {
  const storageKeys = useStorageKey("configuration-submissions");

  return (
    <>
      <ConfigurationsFilterPanel
        filterConfigStorageKey={storageKeys.filterConfigStorageKey}
      />
      <ConfigurationsTablePanel {...storageKeys} />
    </>
  );
};
