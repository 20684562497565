import {
  FormSchemaDataType,
  FormSchemaSectionType,
  FormSubmissionAnswerType,
} from "@validereinc/domain";
import get from "lodash/get";
import isPlainObject from "lodash/isPlainObject";

export const $in = ({
  value,
  comparator,
}: {
  value: string | number;
  comparator: Array<string | number>;
}) => comparator.includes(value);

export const $eq = ({
  value,
  comparator,
}: {
  value: boolean | string | number | Date;
  comparator: boolean | string | number | Date;
}) => value === comparator;

export const $lt = ({
  value,
  comparator,
}: {
  value: number;
  comparator: number;
}) => value < comparator;

export const $lte = ({
  value,
  comparator,
}: {
  value: number;
  comparator: number;
}) => value <= comparator;

export const $gt = ({
  value,
  comparator,
}: {
  value: number;
  comparator: number;
}) => value > comparator;

export const $gte = ({
  value,
  comparator,
}: {
  value: number;
  comparator: number;
}) => value >= comparator;

const conditionFunctions = {
  $in,
  $eq,
  $lt,
  $lte,
  $gt,
  $gte,
};

export const areConditionsSatisfied = ({
  conditions,
  values,
  fieldName,
}: {
  conditions:
    | Record<
        string,
        {
          [key in keyof typeof conditionFunctions]: boolean | number | string;
        }
      >
    | string;
  values: Record<string, string | number | boolean | Date>;
  fieldName: string;
}) => {
  if (!conditions) {
    return true;
  }

  let formattedConditions;

  if (typeof conditions === "string") {
    formattedConditions = JSON.parse(conditions);
  } else {
    formattedConditions = conditions;
  }

  return Object.entries(formattedConditions).every(([key, value]) => {
    const parentFieldName = getParentQuestionName({
      currentQuestionId: fieldName,
      formValues: values,
      questionIdToFind: key,
    });

    return !isPlainObject(value)
      ? $eq({ value, comparator: get(values, parentFieldName) })
      : Object.entries(value).every(([condition, comparator]) => {
          return conditionFunctions?.[condition]({
            value: get(values, parentFieldName),
            comparator,
          });
        });
  });
};

export const getParentQuestionName = ({
  currentQuestionId,
  formValues,
  questionIdToFind,
}: {
  currentQuestionId: string;
  formValues: Record<string, unknown>;
  questionIdToFind: string;
}) => {
  if (currentQuestionId && questionIdToFind && formValues) {
    const splitName = currentQuestionId?.split(".");

    const nameStart = `answers.${splitName[1]}`;

    const value = get(
      formValues,
      `${nameStart}.${splitName[2]}.${questionIdToFind}`
    );

    if (value) {
      return `${nameStart}.${splitName[2]}.${questionIdToFind}.value`;
    }

    const fieldName = Object.entries(formValues?.answers ?? {}).find(
      ([key, objectValue]) =>
        key !== splitName[1] && objectValue?.[0]?.[questionIdToFind]
    )?.[0];

    return `answers.${fieldName}.0.${questionIdToFind}.value`;
  }
};

export const getSmartDefaultValues = (
  formSchema: FormSchemaDataType | undefined,
  contextForDefaults: {
    now?: string;
    currentUserName?: string;
    associatedAssetId?: string;
    associatedAssetType?:
      | "facility"
      | "equipment"
      | "device"
      | "flow"
      | "asset_group";
    defaultValues?: Record<string, string>;
  } = { defaultValues: {} }
) => {
  if (!formSchema) return {};

  return {
    answers: formSchema?.config?.sections.reduce(
      (
        total: Record<string, Array<Record<string, FormSubmissionAnswerType>>>,
        { id, questions }: FormSchemaSectionType
      ) => ({
        ...total,
        [id]: [
          questions.reduce<Record<string, FormSubmissionAnswerType>>(
            (questionDefaults, qid) => {
              const question = formSchema?.config?.questions[qid];

              if (!question) return questionDefaults;

              questionDefaults[qid] = {
                value:
                  contextForDefaults?.defaultValues?.[
                    `$.questions.${qid}.default_answer`
                  ] ??
                  question?.default_answer ??
                  "",
              };

              switch (question.type) {
                case "question":
                  switch (question.data_type) {
                    case "date-time":
                    case "date": {
                      if (
                        questionDefaults[qid]?.value === "$now" &&
                        contextForDefaults.now
                      )
                        questionDefaults[qid] = {
                          value: contextForDefaults.now,
                        };
                      break;
                    }
                    case "string": {
                      if (
                        questionDefaults[qid]?.value === "$user_name" &&
                        contextForDefaults.currentUserName
                      )
                        questionDefaults[qid] = {
                          value: contextForDefaults.currentUserName,
                        };
                      break;
                    }
                    case "lookup": {
                      if (
                        !contextForDefaults.associatedAssetId ||
                        !contextForDefaults.associatedAssetType ||
                        contextForDefaults.associatedAssetType !==
                          question.lookup_entity_type
                      )
                        break;

                      questionDefaults[qid] = {
                        value: contextForDefaults.associatedAssetId,
                      };
                    }
                  }
              }
              return questionDefaults;
            },
            {}
          ),
        ],
      }),
      {}
    ),
  };
};
