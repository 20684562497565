import {
  CREATE_FORM_CATEGORY_DIALOG_TITLE,
  CreateFormCategoryDialog,
} from "#src/batteries-included-components/Dialogs/CreateFormCategoryDialog";
import { FormCategoriesPanel } from "#src/batteries-included-components/Panels/CardPanels/FormCategoriesPanel";
import { FormCategoriesFilterPanel } from "#src/batteries-included-components/Panels/FilterPanels/FormCategoriesFilterPanel";
import {
  useHasPermission,
  useIsFeatureAvailable,
} from "#src/contexts/AuthenticatedContext.helpers";
import { useStorageKey } from "#src/hooks/useStorageKey";
import { FORMS_BREADCRUMB } from "#src/routes/forms";
import { FORMS_CATEGORIES_BREADCRUMB } from "#src/routes/forms/categories";
import { Button, Page, useFilters } from "@validereinc/common-components";
import React, { useState } from "react";

type FormCategoryFilters = {
  page: number;
  name?: string;
};

export const FormCategoryListPage = () => {
  const [canWriteFormSchemas] = useHasPermission("form_schema:write");
  const [isCreatePageAvailable] = useIsFeatureAvailable({
    featureFlagQuery: "dev:beta",
  });
  const [isCreateFormCategoryDialogOpen, setIsCreateFormCategoryDialogOpen] =
    useState(false);

  const onOpenCreateFormCategoryDialog = () =>
    setIsCreateFormCategoryDialogOpen(true);

  const onCloseCreateFormCategoryDialog = () =>
    setIsCreateFormCategoryDialogOpen(false);

  const { filterConfigStorageKey } = useStorageKey("form-categories-page");

  const [filters] = useFilters<FormCategoryFilters>(filterConfigStorageKey);

  return (
    <>
      <Page
        title="Categories"
        breadcrumbs={[FORMS_BREADCRUMB, FORMS_CATEGORIES_BREADCRUMB]}
        actionRow={
          isCreatePageAvailable && canWriteFormSchemas
            ? [
                <Button
                  key="create-form-category-action"
                  variant="primary"
                  onClick={onOpenCreateFormCategoryDialog}
                >
                  {CREATE_FORM_CATEGORY_DIALOG_TITLE}
                </Button>,
              ]
            : undefined
        }
      >
        <FormCategoriesFilterPanel
          filterConfigStorageKey={filterConfigStorageKey}
        />

        <FormCategoriesPanel filters={filters} />
      </Page>

      <CreateFormCategoryDialog
        isOpen={isCreateFormCategoryDialogOpen}
        onClose={onCloseCreateFormCategoryDialog}
      />
    </>
  );
};
