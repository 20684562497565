import {
  getSourceTypeDisplayLabel,
  getStatusDisplayLabel,
} from "#routes/data-tools/submissions/datasets/DataSetsList.helpers";
import {
  ActivityAction,
  ActivityResource,
  AssetType,
  DeviceStatus,
  EquipmentStatus,
  FacilityStatus,
  FormSubmissionStatus,
  TransactionSources,
  TransactionStatus,
} from "@validereinc/domain";
import startCase from "lodash/startCase";

/**
 * Converts a given object-based mapping of keys to values, to a list of options compatible with a select / dropdown input
 * @returns an array of options each with shape { label: string, key: any }
 */
export const convertMapToOpts = <T extends Record<string, string>>(
  /** object-based mapping of keys to values. no restrictions on the type of the value. */
  map: T,
  /** a predicate that is applied to each key/value in the provided map to get the label for each option. provide null to not apply any transformation. */
  labelTransformer:
    | (([key, value]: [keyof T, T[keyof T]]) => string)
    | null = ([key]) =>
    typeof key === "string" ? key.toLowerCase().replace(/_/g, " ") : String(key)
) =>
  map?.constructor === Object
    ? Object.entries(map).map(([key, value]) => ({
        label:
          labelTransformer && typeof labelTransformer === "function"
            ? labelTransformer([key, value] as [keyof T, T[keyof T]])
            : key,
        value,
      }))
    : [];

export const OPERATOR = {
  LESS_THAN: "<",
  LESS_THAN_OR_EQUAL_TO: "<=",
  GREATER_THAN: ">",
  GREATER_THAN_OR_EQUAL_TO: ">=",
  EQUAL_TO: "==",
};
export const OPERATOR_OPTIONS = convertMapToOpts(OPERATOR);

export const AGGREGATOR = {
  MIN: "min",
  MAX: "max",
  MEAN: "mean",
  COUNT: "count",
  SUM: "sum",
  AVERAGE: "avg",
};
export const AGGREGATOR_OPTIONS = convertMapToOpts(AGGREGATOR, null);
export const AGGREGATOR_OPTIONS_READABLE = convertMapToOpts(
  AGGREGATOR,
  ([key]) => startCase(key.toLowerCase())
);

// We don't allow the user to change the status to draft
const { DRAFT: _draft, ...restFormSubmissionStatuses } = FormSubmissionStatus;
export const FORM_SUBMISSION_STATUS_OPTIONS = convertMapToOpts(
  restFormSubmissionStatuses,
  ([key]) => startCase(String(key).toLowerCase())
);

export const FACILITY_STATUS_OPTIONS = convertMapToOpts(
  FacilityStatus,
  ([_, value]) => startCase(value)
);

export const EQUIPMENT_STATUS_OPTIONS = convertMapToOpts(
  EquipmentStatus,
  ([_, value]) => startCase(value)
);

export const DEVICE_STATUS_OPTIONS = convertMapToOpts(
  DeviceStatus,
  ([_, value]) => startCase(value)
);

export const MASS_POLLUTANTS = [
  { label: "CO2e", dataKey: "co2_eq", mass: "mass_co2eq" },
  { label: "CO2", dataKey: "co2", mass: "mass_co2" },
  { label: "CH4", dataKey: "c1", mass: "mass_ch4" },
  { label: "N2O", dataKey: "n2o", mass: "mass_n2o" },
];

export const INIT_REPORTING_GROUP = "subpartw";
export const INIT_MASS_POLLUTANT = MASS_POLLUTANTS[0];

export const TRANSACTION_STATUS_OPTIONS = convertMapToOpts(
  TransactionStatus,
  ([_, value]) => getStatusDisplayLabel(value)
);

export const TRANSACTION_SOURCE_OPTIONS = convertMapToOpts(
  TransactionSources,
  ([_, value]) => getSourceTypeDisplayLabel(value)
);

export const ResourceTypeOptions = convertMapToOpts(AssetType, ([_, value]) =>
  startCase(value)
);

export const ActivityActionOptions = convertMapToOpts(
  ActivityAction,
  ([_, value]) => startCase(value)
);

export const ActivityResourceTypeOptions = convertMapToOpts(
  ActivityResource,
  ([_, value]) => startCase(value)
);
