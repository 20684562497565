import { useLocation, useSearchParams } from "#src/Routers/hooks";
import { useIsFeatureAvailable } from "#src/contexts/AuthenticatedContext.helpers";
import { useBreadcrumbsFromRoute } from "#src/utils/route";
import { Page, Tab } from "@validereinc/common-components";
import React from "react";
import { DataSubmissionsRoutePath } from ".";
import { ConfigurationsRoutePath } from "./configurations";
import { DataSetsListTab } from "#src/routes/data-tools/submissions/datasets/DataSetsListTab";
import { DataSetsRoutePath } from "./datasets";
import { ConfigurationsListTab } from "#src/routes/data-tools/submissions/configurations/ConfigurationsListTab";

export const DataSubmissionsListPageTabs = {
  DataSetsTab: "datasets",
  ConfigurationsTab: "configurations",
} as const;

export const DataSubmissionsListPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const location = useLocation();

  const DEFAULT_TAB_KEY =
    ConfigurationsRoutePath.path === location.pathname
      ? DataSubmissionsListPageTabs.ConfigurationsTab
      : DataSubmissionsListPageTabs.DataSetsTab;

  const [breadcrumbs] = useBreadcrumbsFromRoute(DataSubmissionsRoutePath);

  const [isConfigurationsAvailable] = useIsFeatureAvailable({
    featureFlagQuery: "core:templated_configurations",
    permissionQuery: "templated_configuration_runs:read",
  });

  return (
    <Page
      title={DataSubmissionsRoutePath.title}
      breadcrumbs={breadcrumbs}
      activeTabKey={searchParams?.tab ?? DEFAULT_TAB_KEY}
      onActiveTabKeyChange={(tabKey: string) => {
        setSearchParams({ tab: tabKey });
      }}
    >
      <Tab
        title={DataSetsRoutePath.title}
        tabKey={DataSubmissionsListPageTabs.DataSetsTab}
      >
        <DataSetsListTab />
      </Tab>
      {isConfigurationsAvailable && (
        <Tab
          title={ConfigurationsRoutePath.title}
          tabKey={DataSubmissionsListPageTabs.ConfigurationsTab}
        >
          <ConfigurationsListTab />
        </Tab>
      )}
    </Page>
  );
};
