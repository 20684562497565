import { RoutePath } from "#src/utils/route";
import { DataSubmissionsRoutePath } from "..";

export { ConfigurationsListTab } from "./ConfigurationsListTab";

export const ConfigurationsRoutePath = DataSubmissionsRoutePath.concat(
  new RoutePath({
    path: "/configurations",
    title: "Configuration Submissions",
    isPresentational: true,
  })
);
