import { useStorageKey } from "#src/hooks/useStorageKey";
import React from "react";
import { DataSetsFilterPanel } from "./DataSetsFilterPanel";
import { DataSetsTablePanel } from "./DataSetsTablePanel";

export const DataSetsListTab = () => {
  const storageKeys = useStorageKey("dataset-submissions");

  return (
    <>
      <DataSetsFilterPanel
        filterConfigStorageKey={storageKeys.filterConfigStorageKey}
      />
      <DataSetsTablePanel {...storageKeys} />
    </>
  );
};
