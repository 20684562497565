import { DEFAULT_QUERY_OPTIONS } from "#hooks/adapters/adapterUtils";
import { useParams } from "#routers/hooks";
import { DataSetDetailPageParamType } from "#routes/data-tools/submissions/datasets/[transactionId]/detail";
import { downloadFile } from "#utils/download";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAlert } from "@validereinc/common-components";
import {
  GetListRequestType,
  TransactionAdapter,
  TransactionGetListFilterType,
} from "@validereinc/domain";

export const useListTransactions = (
  apiParams: GetListRequestType<TransactionGetListFilterType>
) =>
  useQuery({
    queryKey: ["transaction", "list", apiParams],
    queryFn: () => TransactionAdapter.getList(apiParams),
    ...DEFAULT_QUERY_OPTIONS,
  });

export const useGetOneTransaction = () => {
  const { transactionId } = useParams<DataSetDetailPageParamType>();

  const params = {
    id: transactionId,
    meta: { history: true },
  };

  return useQuery({
    queryKey: ["transaction", "getOne", params],
    queryFn: () => TransactionAdapter.getOne(params),
    enabled: !!params.id,
    ...DEFAULT_QUERY_OPTIONS,
  });
};

export const useGetOneTransactionDetail = () => {
  const { transactionId } = useParams<DataSetDetailPageParamType>();

  const params = {
    id: transactionId,
  };

  return useQuery({
    queryKey: ["transaction", "details", "getOne", params],
    queryFn: () => TransactionAdapter.details.getOne(params),
    enabled: !!params.id,
    ...DEFAULT_QUERY_OPTIONS,
  });
};

export const useDownloadErrors = () => {
  const { addAlert } = useAlert();

  return useMutation({
    mutationFn: async ({
      transactionId,
      fileName,
    }: {
      transactionId: string;
      fileName: string;
    }) => {
      const data = await TransactionAdapter.details.getOne({
        id: transactionId,
        meta: { accept: "text/csv" },
      });
      downloadFile(fileName, data);
    },
    onSuccess: () => {
      addAlert?.({
        variant: "success",
        message: "Successfully downloaded submission errors",
      });
    },
    onError: (err) => {
      addAlert?.({
        variant: "error",
        message: "Could not download submission errors",
      });
      console.error(err);
    },
  });
};

export const useOriginalSubmissionFileDownload = () => {
  const { addAlert } = useAlert();

  return useMutation({
    mutationFn: async ({
      transactionId,
      fileName,
    }: {
      transactionId: string;
      fileName: string;
    }) => {
      const data = await TransactionAdapter.download.getOne({
        transactionId,
        hasTransformed: false,
      });

      // download the blob as a file
      downloadFile(fileName, data);
    },
    onSuccess: () => {
      addAlert?.({
        variant: "success",
        message: "Successfully downloaded original submission",
      });
    },
    onError: (err) => {
      addAlert?.({
        variant: "error",
        message: "Could not download the original submission",
      });
      console.error(err);
    },
  });
};
